<template>
	<div class="cta-wrapper" v-if="label || link">
		<component
			:is="tagName"
			:aria-label="altText"
			:class="['cta-btn', color, isIconButton ? 'cta-btn--icon' : '']"
			:data-goal="dataGoal"
			:href="link"
			:rel="relOption"
			:tabindex="tabIndex"
			:target="targetOption"
			@click="onClickMethod"
			:disabled="disabled"
			:data-fevo-offer-id="fevoId"
		>
			{{ label }}
			<span v-if="isIconButton" class="cta-btn__icon">
				<svg-icon v-if="icon" :icon="icon" />
				<slot v-if="hasSvgSlot && !icon" />
			</span>
		</component>
	</div>
</template>

<script>
import SvgIcon from '../SvgIcon';
import { EventBus } from '../../utils/EventBus';
import useTracking from '../../composables/useTracking';

export default {
	name: 'DefaultButton',
	components: {
		SvgIcon,
	},
	props: {
		altText: { type: String, required: false, default: undefined },
		clickHandler: { type: Function, required: false, default: undefined },
		color: { type: String, required: false, default: '' },
		gtm: { type: String, required: false, default: undefined },
		gtmHeader: { type: String, required: false, default: undefined },
		ga4: { type: String, required: false, default: undefined },
		icon: { type: String, required: false, default: undefined },
		label: { type: String, required: false, default: 'Placeholder' },
		link: { type: String, required: false, default: undefined },
		modalId: { type: String, required: false, default: undefined },
		targetBlank: { type: Boolean, required: false, default: false },
		dataGoal: { type: String, required: false, default: undefined },
		tabIndex: { type: Number, required: false, default: undefined },
		disabled: { type: Boolean, require: false, default: false },
		fevoId: { type: String, require: false, default: undefined },
		tracking: { type: Object, required: false, default: null },
	},
	computed: {
		hasSvgSlot() {
			return this.$slots.default && this.$slots.default.indexOf('<svg') >= 0;
		},
		isIconButton() {
			return Boolean(this.icon || this.hasSvgSlot);
		},
		onClickMethod() {
			if (this.modalId) return this.openModal;
			if (this.clickHandler) return this.clickHandler;
			return this.pushEvents;
		},
		relOption() {
			return this.targetBlank ? 'noreferrer noopener' : undefined;
		},
		targetOption() {
			return this.targetBlank ? '_blank' : undefined;
		},
		tagName() {
			return this.link ? 'a' : 'button';
		},
	},
	methods: {
		openModal: function () {
			EventBus.$emit('open-modal', this.modalId);
			this.pushEvents();
		},
		pushEvents() {
			const { pushGua, pushGa4, pushTracking } = useTracking();
			pushGua(this.gtm);
			pushGa4(this.ga4);
			pushTracking(this.tracking?.click);
		},
	},
};
</script>

<style scoped></style>
