export default {
	install(Vue, store) {
		const screenSizes = [
			{ name: 'mobile', size: 0 },
			{ name: 'tablet', size: 600 },
			{ name: 'desktop', size: 1280 },
		];

		function init() {
			screenSizes.forEach((sizeItem, index) => {
				const currentMediaQuery = window.matchMedia(
					`(min-width: ${sizeItem.size}px)`
				);

				screenSizes[index].mediaQuery = currentMediaQuery;
				currentMediaQuery.addListener(updateWindowSize);
			});

			screenSizes.sort((a, b) => a.size - b.size);
			updateWindowSize();
		}

		function updateWindowSize() {
			let activeScreenName = '';

			screenSizes.forEach((sizeItem) => {
				if (sizeItem.mediaQuery.matches) {
					activeScreenName = sizeItem.name;
				}
			});
			store.commit('SET_NAME', activeScreenName);
		}

		init();
	},
};
