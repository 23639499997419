<template>
	<div
		class="split-block"
		:style="splitBlockStyle"
		:class="{ 'split-block--inverted': inverted }"
	>
		<div class="split-block__item-1">
			<slot name="item1"></slot>
		</div>
		<div class="split-block__item-2">
			<slot name="item2"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SplitBlock',

	props: {
		bgColor: { type: String, required: false, default: null },
		inverted: { type: Boolean, required: false, default: false },
	},
	computed: {
		splitBlockStyle() {
			return {
				backgroundColor: this.bgColor,
			};
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="postcss" scoped></style>
