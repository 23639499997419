1
<template>
	<div class="video-carousel-thumb-list">
		<button
			v-if="currentThumbIndex > 0"
			class="video-carousel-thumb-list__prev-button"
			:aria-label="labelPrevButton"
			@click="onCycleThumbClick(false)"
		></button>

		<div ref="thumbListContainer" class="video-carousel-thumb-list__wrapper">
			<ul
				ref="thumbList"
				class="video-carousel-thumb-list__list"
				:style="thumbListStyle"
			>
				<li
					v-for="(data, index) in thumbData"
					:key="index"
					ref="thumbItem"
					class="video-carousel-thumb-list__item"
				>
					<button
						class="video-carousel-thumb-list__item__button"
						:disabled="index === selectedIndex"
						:aria-label="labelPlayVideoButton"
						@click="onThumbClick(index)"
					>
						<img
							class="video-carousel-thumb-list__item__image"
							width="320"
							height="180"
							alt=""
							loading="lazy"
							:src="videoListThumbUrl[index]"
						/>
						<icon-base
							icon-name="play"
							class="video-carousel-thumb-list__item__icon"
						>
							<icon-play-full />
						</icon-base>
					</button>
				</li>
			</ul>
		</div>

		<button
			v-if="thumbListTranslation < extraContentSpace"
			class="video-carousel-thumb-list__next-button"
			:aria-label="labelNextButton"
			@click="onCycleThumbClick(true)"
		></button>
	</div>
</template>

<script>
import IconBase from '../SvgIcons/IconBase';
import IconPlayFull from '../SvgIcons/icons/IconPlayFull';

export default {
	name: 'VideoCarouselThumbList',

	components: {
		IconBase,
		IconPlayFull,
	},

	props: {
		thumbData: { type: Array, required: true },
		selectedIndex: { type: Number, default: 0 },
		labelPrevButton: { type: String, required: true },
		labelNextButton: { type: String, required: true },
		labelPlayVideoButton: { type: String, required: true },
	},

	data() {
		return {
			currentThumbIndex: 0,
			contentWidth: null,
			viewportWidth: null,
			thumbItemWidth: null,
			thumbItemMarginRight: null,
			thumbListTranslation: 0,
			resizeTimeoutId: 0,
		};
	},

	computed: {
		videoListThumbUrl() {
			const urlList = [];

			for (const thumbDataItem of this.thumbData) {
				urlList.push(
					`https://img.youtube.com/vi/${thumbDataItem.videoId}/mqdefault.jpg`
				);
			}

			return urlList;
		},

		thumbListStyle() {
			return {
				transform: `translateX(-${this.thumbListTranslation}px)`,
			};
		},

		extraContentSpace() {
			const extraSpace = this.contentWidth - this.viewportWidth;
			return extraSpace > 0 ? extraSpace : 0;
		},
	},

	mounted() {
		this.updateBoxMesures();
		window.addEventListener('resize', this.onWindowresize);
	},

	methods: {
		onThumbClick(index) {
			this.$emit('thumb-click', index);
		},

		onCycleThumbClick(isForward = true) {
			this.currentThumbIndex += isForward ? 1 : -1;
			this.updateBoxPosition();
		},

		onWindowresize() {
			window.clearTimeout(this.resizeTimeoutId);

			this.resizeTimeoutId = window.setTimeout(this.updateBoxMesures, 100);
		},

		updateBoxMesures() {
			// changes with all window size changes (TODO: bind to throttle window resize event handler)
			this.viewportWidth = this.$refs.thumbListContainer.clientWidth;

			// changes with breakpoint changes (TODO: could use a tool like in responsiveBgImageSwitcher)
			const thumbItem = this.$refs.thumbItem[0];
			const thumbStyle = window.getComputedStyle(thumbItem);

			this.contentWidth = this.$refs.thumbList.scrollWidth;
			this.thumbItemWidth = thumbItem.clientWidth;
			this.thumbItemMarginRight = Number(thumbStyle.marginRight.split('px')[0]);

			this.updateBoxPosition();
		},

		updateBoxPosition() {
			// prettier-ignore
			const rawScrollWidth = this.currentThumbIndex * (this.thumbItemWidth + this.thumbItemMarginRight);
			// prettier-ignore
			this.thumbListTranslation = (rawScrollWidth > this.extraContentSpace)
				? this.extraContentSpace
				: rawScrollWidth;
		},
	},
};
</script>

<style lang="scss" scoped>
.video-carousel-thumb-list {
	position: relative;
}

.video-carousel-thumb-list__wrapper {
	overflow: hidden;
}

.video-carousel-thumb-list__list {
	display: flex;
	margin: 0;
	padding: 0 15px 8px;
	transition: all 0.222s ease;

	@include desktop {
		padding-left: 25px;
	}
}

.video-carousel-thumb-list__item {
	@include tablet {
		padding-right: 4px;
	}

	&:last-child {
		padding-right: 15px;

		@include desktop {
			padding-right: 25px;
		}
	}
}

.video-carousel-thumb-list__item__button {
	@include buttonReset;

	background-position: center;
	background-size: cover;
	display: block;
	height: 70px;
	position: relative;
	width: 145px;
	overflow: hidden;

	@include tablet {
		height: 118px;
		width: 210px;
	}

	&:hover,
	&:focus {
		.video-carousel-thumb-list__item__icon {
			transform: translate(-50%, -50%) scale(1.3);
		}
	}

	&:disabled {
		cursor: auto;
	}
}

.video-carousel-thumb-list__item__icon {
	fill: $color-white;
	height: 20px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: all 0.222s ease;
	width: 20px;

	@include tablet {
		height: 30px;
		width: 30px;
	}
}

.video-carousel-thumb-list__prev-button,
.video-carousel-thumb-list__next-button {
	@include buttonReset;

	background-color: $color-white;
	border-radius: 0 16px 16px 0;
	height: 32px;
	position: absolute;
	top: 50%;
	transition: background-color 0.33s ease;
	width: 32px;
	z-index: 5;

	@include tablet {
		border-radius: 0 25px 25px 0;
		height: 40px;
		width: 40px;
	}

	&:after {
		border-width: 0 0 2px 2px;
		content: '';
		height: 10px;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%) rotate(45deg);
		width: 10px;

		@include desktop {
			border-width: 0 0 3px 3px;
			height: 12px;
			left: 50%;
			width: 12px;
		}
	}
}

.video-carousel-thumb-list__prev-button {
	left: 0;
	transform: translateY(-50%);
}

.video-carousel-thumb-list__next-button {
	right: 0;
	transform: translateY(-50%) rotate(180deg);
}
</style>
