export default {
	data() {
		return {
			$_mediaQueryData: [],
			responsiveBackgroundImageUrl: '',
			breakpoints: {
				default: 0,
				// tablet: 600,
				desktop: 600,
			},
		};
	},

	props: {
		responsiveBackgroundImageList: {
			type: Object,
			required: false,
			default: function () {
				return null;
			},
		},
	},

	computed: {
		sectionStyle() {
			return {
				backgroundImage: this.$data.responsiveBackgroundImageUrl,
			};
		},
	},

	methods: {
		$_updateImageUrl() {
			let newImageUrl = '';

			this.$data.$_mediaQueryData.forEach((el) => {
				if (el.mediaQuery.matches) {
					newImageUrl = el.imageUrl;
				}
			});

			this.$data.responsiveBackgroundImageUrl = newImageUrl;
		},
	},

	created() {
		if (!this.responsiveBackgroundImageList) return;

		for (const mediaQueryKey in this.responsiveBackgroundImageList) {
			if (
				!this.breakpoints[mediaQueryKey] &&
				this.breakpoints[mediaQueryKey] !== 0
			) {
				continue;
			}

			const currentMediaQuery = window.matchMedia(
				`(min-width: ${this.breakpoints[mediaQueryKey]}px)`
			);

			this.$data.$_mediaQueryData.push({
				size: this.breakpoints[mediaQueryKey],
				mediaQuery: currentMediaQuery,
				imageUrl: this.responsiveBackgroundImageList[mediaQueryKey],
			});

			currentMediaQuery.addListener(this.$_updateImageUrl);
		}

		this.$data.$_mediaQueryData.sort((a, b) => a.size - b.size);

		this.$_updateImageUrl();
	},

	unmounted() {
		this.$data.$_mediaQueryData.forEach((el) => {
			el.mediaQuery.removeListener(this.$_updateImageUrl);
		});
	},
};
