<template>
	<picture>
		<source
			v-for="(picture, index) in source.modern"
			:key="index"
			:srcset="picture.srcset"
			:media="picture.media"
			:type="picture.type"
		/>
		<img
			v-if="source.fallback.src"
			:src="source.fallback.src"
			:alt="decorative ? '' : source.fallback.description"
			:loading="source.fallback.loading"
			onload="this.classList.add('loaded')"
		/>
		<div class="picture__placeholder" v-else>
			<icon-base icon-name="missing file" width="80" height="80">
				<icon-broken-file />
			</icon-base>
		</div>
	</picture>
</template>

<script>
import IconBase from '../SvgIcons/IconBase';
import IconBrokenFile from '../SvgIcons/icons/IconBrokenFile';

export default {
	name: 'DefaultPicture',
	components: {
		IconBase,
		IconBrokenFile,
	},
	props: {
		decorative: { type: Boolean, default: false },
		source: {
			type: Object,
			required: true,
			validator: (value) => {
				return (value.fallback.src && value.fallback.loading) !== undefined;
			},
		},
	},
};
</script>

<style lang="scss" scoped>
.picture__placeholder {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	background-color: #767676;
}
</style>
