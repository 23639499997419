<template>
	<div class="card" :style="{ background: cardColor }">
		<div class="card__wrapper">
			<div class="card__content">
				<div class="card__media">
					<div class="card__featured-image">
						<div class="card__poster">
							<div class="card__poster-container">
								<slot name="poster"></slot>
							</div>
						</div>
					</div>
				</div>

				<div class="card__info">
					<slot name="header"></slot>
					<component class="card__title" :is="titleHeadingLevel">
						<a v-if="link" :href="link" @click="trackingClickHandler(gtm, ga4)">
							<span class="hidden">
								{{ linkAlt }}
								<br />
							</span>
							{{ title }}
						</a>
						<template v-else>
							{{ title }}
						</template>
					</component>
					<p class="card__subtitle" v-if="subtitle">
						{{ subtitle }}
					</p>
					<div class="card__body">
						{{ content }}
						<slot name="body"></slot>
					</div>
					<div class="card__footer" v-if="hasFooterSlot">
						<slot name="footer"></slot>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import GtmHelpers from '../../mixins/GtmHelpers.js';

export default {
	name: 'DefaultCard',
	mixins: [GtmHelpers],
	props: {
		cardColor: { type: String, required: false, default: '#282828' },
		title: { type: String, required: true },
		subtitle: { type: String, required: false, default: '' },
		content: { type: String, required: false, default: '' },
		link: { type: String, required: false },
		linkAlt: { type: String, required: false },
		titleHeadingLevel: { type: String, required: false, default: 'p' },
		gtm: { type: String, required: false, default: undefined },
		ga4: { type: String, required: false, default: undefined },
	},
	computed: {
		hasFooterSlot() {
			return !!this.$slots['footer'];
		},
	},
};
</script>
