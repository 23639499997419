<template>
	<div class="modal-image-mosaic">
		<image-mosaic :modal-id="modalCarouselId" :mosaic-data="mosaicData">
			<template v-slot:title>
				<slot name="title" />
			</template>

			<template v-slot:description>
				<slot name="description" />
			</template>

			<template v-slot:ornement>
				<slot name="ornement" />
			</template>
		</image-mosaic>

		<portal>
			<default-modal
				:modal-id="modalCarouselId"
				:btn-close-modal="btnCloseModal"
				modifiers="modal--carousel"
			>
				<template v-slot:modal-container>
					<default-carousel
						:carousel-id="modalCarouselId"
						:arrow="modalArrow"
						:pagers="pagination"
						:sliders="mosaicData"
						:slide-headind-level="slideHeadindLevel"
					></default-carousel>
				</template>
			</default-modal>
		</portal>
	</div>
</template>

<script>
import DefaultModal from '../DefaultModal';
import DefaultCarousel from '../DefaultCarousel';
import ImageMosaic from '../ImageMosaic';

export default {
	components: {
		DefaultCarousel,
		DefaultModal,
		ImageMosaic,
	},

	props: {
		modalCarouselId: { type: String, required: true },
		modalArrow: { type: String, required: false, default: '' },
		mosaicData: { type: Array, required: true },
		slideHeadindLevel: { type: String, default: 'h3' },
		pagination: { type: Boolean, required: false, default: false },
		btnCloseModal: { type: String, required: false, default: '' },
	},
};
</script>

<style lang="scss"></style>
