// wrap in </p> if there is not already an opening and closing one
export default {
	methods: {
		wrapInParagraph: function (content = '') {
			if (content.search(/<p/i) < 0 || content.search(/<\/p>/i) < 0) {
				content = `<p>${content}</p>`;
			}

			return content;
		},
	},
};
