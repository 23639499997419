<template>
	<button class="close-btn" aria-label="Close" :class="color" @click="event">
		<span class="close-btn__icon" v-html="svgIcon"></span>
	</button>
</template>

<script>
export default {
	name: 'CloseButton',
	props: {
		color: { type: String, required: false, default: '-black' },
		event: { type: Function, required: true },
		svgIcon: {
			type: String,
			required: false,
			default:
				'<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path data-name="Path 1586" d="M17.522 20L10 12.478 2.477 20 0 17.522 7.522 10 0 2.478 2.477 0l7.522 7.522L17.521 0l2.478 2.478L12.477 10l7.522 7.522z" fill="#fff" fill-rule="evenodd"/></svg>',
		},
	},
};
</script>

<style scoped></style>
