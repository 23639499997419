export default {
	install(Vue) {
		Vue.prototype.$_idIndex = 0;

		Vue.prototype.$_getUid = function (prefix = 'uid-') {
			const zeroPaddingLength = 6;
			const id = this.$root.$_idIndex;
			this.$root.$_idIndex++;
			const idLength = zeroPaddingLength - String(id).length;
			let zeroPadding = '';

			for (let i = 0; i < idLength; i++) {
				zeroPadding += '0';
			}

			return prefix + zeroPadding + id;
		};
	},
};
