<template>
	<div :id="id" class="default-section" :style="sectionStyle">
		<div class="default-section__container">
			<slot name="title"></slot>
			<slot></slot>
		</div>
	</div>
</template>

<script>
import ResponsiveBackgroundImageMixin from '../../mixins/ResponsiveBackgroundImageMixin.js';

export default {
	mixins: [ResponsiveBackgroundImageMixin],
	props: {
		bgColor: { type: String, required: false, default: '#fff' },
		zIndex: { type: Number, required: false, default: 0 },
		bgFadeColor: { type: String, required: false, default: 'transparent' },
		fontColor: { type: String, required: false, default: '#000' },
		id: { type: String, required: false, default: null },
	},
	data: function () {
		return {
			bgGradient: '',
			bgFadeGradient: '',
			bgColorSimple: null,
		};
	},
	computed: {
		sectionStyle() {
			return {
				color: this.fontColor,
				fill: this.fontColor,
				'--font-color': this.fontColor,
				'--bg-color': this.bgColor,
				backgroundColor: this.bgColorSimple,
				backgroundImage: this.responsiveBackgroundImageUrl
					? `url(${this.responsiveBackgroundImageUrl}) ${this.bgFadeGradient}`
					: this.bgGradient,
				zIndex: this.zIndex,
			};
		},
		hasFadeColor() {
			return this.bgFadeColor !== '';
		},
	},
	mounted() {
		const reg = new RegExp('linear-gradient');
		if (reg.test(this.bgColor)) {
			this.bgGradient = this.bgColor;
		} else {
			this.bgGradient = null;
			this.bgColorSimple = this.bgColor;
		}
		if (this.bgFadeColor !== '') {
			this.bgFadeGradient = `, linear-gradient(${this.bgFadeColor},${this.bgFadeColor})`;
		}
	},
};
</script>
