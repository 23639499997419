<template>
	<div class="default-carousel__container">
		<div :ref="carouselId" class="swiper default-carousel">
			<div class="swiper-wrapper">
				<div
					v-for="(slide, index) in sliders"
					:key="index"
					class="swiper-slide"
				>
					<div class="default-carousel__content">
						<picture v-if="slide.imageMobile">
							<source media="(min-width: 600px)" :srcset="slide.image" />
							<img
								class="default-carousel__content-image swiper-lazy"
								:data-src="slide.imageMobile"
								:alt="slide.altText"
								loading="lazy"
							/>
						</picture>
						<img
							v-else
							class="default-carousel__content-image swiper-lazy"
							:data-src="slide.image"
							:alt="slide.altText"
							loading="lazy"
						/>
						<div class="default-carousel__content-block">
							<component
								:is="slideHeadindLevel"
								v-if="slide.title"
								class="default-carousel__content-title"
							>
								{{ slide.title }}
							</component>

							<div
								v-if="slide.description"
								class="default-carousel__content-desc"
								v-html="wrapInParagraph(slide.description)"
							></div>

							<default-button
								v-if="buttonType"
								:type="buttonType"
								:label="buttonLabel"
								:color="buttonColor"
								:link="slide.url"
							></default-button>
						</div>
						<div
							v-if="slide.content"
							class="default-carousel__content-block-container"
						>
							<div
								v-for="(content, i) in slide.content"
								:key="i"
								class="default-carousel__content-block"
							>
								<component
									:is="slideHeadindLevel"
									class="default-carousel__content-title"
								>
									{{ content.title }}
								</component>

								<div
									class="default-carousel__content-desc"
									v-html="wrapInParagraph(content.description)"
								></div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="pagers" slot="pagination" class="swiper-pagination"></div>
			<button slot="button-prev" class="swiper-button-prev">
				<svg-icon v-if="arrow" :icon="arrow" />
			</button>

			<button slot="button-next" class="swiper-button-next">
				<svg-icon v-if="arrow" :icon="arrow" />
			</button>
		</div>
	</div>
</template>

<script>
import Swiper, { Navigation, Pagination, Lazy } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/lazy';
import DefaultButton from '../DefaultButton';
import { EventBus } from '../../utils/EventBus';
import ContentParser from '../../mixins/ContentParser';
import SvgIcon from '../SvgIcon';

export default {
	name: 'DefaultCarousel',
	components: {
		DefaultButton,
		SvgIcon,
	},

	mixins: [ContentParser],

	props: {
		carouselId: { type: String, required: true },
		arrow: { type: String, required: true },
		slideHeadindLevel: { type: String, default: 'h3' },
		buttonLabel: { type: String, required: false, default: 'Learn More' },
		buttonType: { type: String, required: false, default: null },
		buttonColor: { type: String, required: false, default: '-black' },
		pagers: { type: Boolean, required: false, default: false },
		sliders: { type: Array, required: true },
	},

	data() {
		return {
			swiperOptions: {
				modules: [Navigation, Pagination, Lazy],
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				pagination: {
					el: '.swiper-pagination',
					type: 'fraction',
				},
				loop: true,
				lazy: {
					loadPrevNext: true,
				},
			},
		};
	},

	computed: {
		swiper() {
			return new Swiper(this.$refs[this.carouselId], this.swiperOptions);
		},
	},

	mounted() {
		this.swiper.on('slideChange', this.handleSlideChange.bind(this));
		EventBus.$on('open-modal-transition', this.handleOpenModalTransition);
		EventBus.$on('open-modal', this.handleOpenModal);
	},

	methods: {
		handleOpenModal(modalId, options) {
			if (modalId === this.carouselId) {
				const baseOneIndex = options.index + 1;
				this.swiper.slideTo(baseOneIndex, 0, false);
			}
		},

		handleOpenModalTransition() {
			this.swiper.update();
		},

		handleSlideChange() {
			this.$nextTick(this.setTabIndex);
		},

		setTabIndex() {
			for (let index = 0; index < this.swiper.slides.length; index++) {
				const slide = this.swiper.slides[index];

				const tabableElements = slide.querySelectorAll(
					'button, a[href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])'
				);

				const tabIndexValue = index === this.swiper.activeIndex ? '0' : '-1';

				for (const element of tabableElements) {
					element.setAttribute('tabindex', tabIndexValue);
				}
			}
		},
	},
};
</script>

<style scoped></style>
