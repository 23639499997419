export default {
	methods: {
		pushGtm(gtmEvent) {
			if (!gtmEvent) return;
			if (!window.dataLayer) window.dataLayer = [];

			try {
				const eventAttributes = this.cleanJSON(gtmEvent);

				if (eventAttributes) {
					for (let i = 0; i < eventAttributes.length; i++) {
						window.dataLayer.push(eventAttributes[i]);
					}
				}
			} catch (e) {
				console.warn('[GtmHelpers] - ', e);
			}
		},
		pushGa4(gtmEvent) {
			if (!gtmEvent) return;
			if (!window.dataLayer) window.dataLayer = [];

			try {
				const eventAttributes = this.cleanJSON(gtmEvent);

				if (eventAttributes) {
					for (let i = 0; i < eventAttributes.length; i++) {
						window.dataLayer.push(eventAttributes[i]);
					}
				}
			} catch (e) {
				console.warn('[GtmHelpers] - ', e);
			}
		},
		cleanJSON(strJSON) {
			let s = strJSON
				.replace(/\\n/g, '\\n')
				.replace(/\\'/g, "\\'")
				.replace(/\\"/g, '\\"')
				.replace(/\\&/g, '\\&')
				.replace(/\\r/g, '\\r')
				.replace(/\\t/g, '\\t')
				.replace(/\\b/g, '\\b')
				.replace(/\\f/g, '\\f');

			// remove non-printable and other non-valid JSON chars
			s = s.replace(/[\u0000-\u0019]+/g, '');
			return JSON.parse(s);
		},
		trackingClickHandler(gtm = null, ga4 = null) {
			if (gtm) this.pushGtm(gtm);
			if (ga4) this.pushGa4(ga4);
		},
	},
};
