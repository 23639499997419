<template>
	<div class="video-youtube-player">
		<div
			v-if="!thumbnailClicked"
			class="video-youtube-player__poster"
			@pointerover="warmConnections"
		>
			<img
				class="video-youtube-player__poster__img"
				v-if="(!isPlaylist || posterUrl) && !posterHide"
				:src="videoThumbnail"
				alt=""
				width="320"
				height="180"
				loading="lazy"
				decoding="async"
			/>

			<button
				class="video-youtube-player__btn"
				:data-gtm="gtm"
				:data-ga4="ga4"
				:title="playTitle"
				@click="clickThumbnail"
			>
				<icon-base
					icon-name="play"
					width="44"
					height="44"
					class="video-youtube-player__icon icon"
				>
					<icon-play-full />
				</icon-base>
			</button>
		</div>

		<div class="video-youtube-iframe" v-if="thumbnailClicked">
			<div :id="playerId"></div>
		</div>
	</div>
</template>

<script>
import { nextTick } from 'vue';
import IconBase from '../SvgIcons/IconBase';
import IconPlayFull from '../SvgIcons/icons/IconPlayFull';

export default {
	name: 'VideoYoutubePlayer',

	components: {
		IconBase,
		IconPlayFull,
	},

	emits: ['close'],

	props: {
		gtm: { type: String, required: false, default: null },
		ga4: { type: String, required: false, default: null },
		videoId: { type: String, required: true },
		autoplay: { type: Boolean, default: true },
		posterSize: { type: String, default: 'hqdefault' },
		posterUrl: { type: String, default: null },
		posterHide: { type: Boolean, default: false },
		playTitle: { type: String, default: 'play' },
		isPlaylist: { type: Boolean, default: false },
	},

	data() {
		return {
			thumbnailClicked: false,
			preconnected: false,
			player: null,
			playerId: `player__${this.$_getUid()}`,
		};
	},

	watch: {
		videoId() {
			if (!this.player) return;
			this.player.loadVideoById(this.videoId);
		},
	},

	computed: {
		autoplayNumber() {
			return new Number(this.autoplay);
		},
		videoThumbnail() {
			return this.posterUrl
				? this.posterUrl
				: `https://i.ytimg.com/vi/${this.videoId}/${this.posterSize}.jpg`;
		},
		playerOptions() {
			return this.isPlaylist
				? {
						listType: 'playlist',
						list: this.videoId,
						allowfullscreen: 'allowfullscreen',
						playsinline: 0,
						enablejsapi: 1,
						modestbranding: 1,
						rel: 0,
						autoplay: this.autoplayNumber,
				  }
				: {
						allowfullscreen: 'allowfullscreen',
						playsinline: 0,
						enablejsapi: 1,
						modestbranding: 1,
						rel: 0,
						autoplay: this.autoplayNumber,
				  };
		},
	},

	methods: {
		async clickThumbnail() {
			await this.createScript();
			this.thumbnailClicked = true;
			nextTick(() => this.createPlayer());
		},

		isScriptLoaded() {
			return document.querySelector(
				'script[src*="https://www.youtube.com/iframe_api"]'
			)
				? true
				: false;
		},

		createScript() {
			if (this.isScriptLoaded()) return Promise.resolve(true);
			return new Promise((res, rej) => {
				const script = document.createElement('script');
				script.onload = () => {
					YT.ready(res);
				};
				script.onerror = rej;
				script.src = 'https://www.youtube.com/iframe_api';
				script.async = true;
				document.head.appendChild(script);
			});
		},

		createPlayer() {
			if (this.player && this.player.playVideo) {
				this.player.playVideo();
				return;
			}
			this.player = new YT.Player(this.playerId, {
				height: '390',
				width: '640',
				videoId: this.videoId,
				events: {
					onReady: (event) => {
						event.target.playVideo();
						document.getElementById(this.playerId).focus();
					},
					onStateChange: (event) => {
						if (!this.isPlaylist && event.data == 0) {
							this.$emit('close');
						}
					},
				},
				playerVars: this.playerOptions,
			});
		},

		stopVideo() {
			this.thumbnailClicked = false;
			if (!this.player || !this.player.stopVideo) return;
			this.player.stopVideo();
		},

		warmConnections() {
			if (this.preconnected) return;
			this.addPrefetch('preconnect', 'https://www.youtube.com');
			this.addPrefetch('preconnect', 'https://www.google.com');
			this.addPrefetch('preconnect', 'https://googleads.g.doubleclick.net');
			this.addPrefetch('preconnect', 'https://static.doubleclick.net');
			this.preconnected = true;
		},

		addPrefetch(kind, url, as) {
			const linkEl = document.createElement('link');
			linkEl.rel = kind;
			linkEl.href = url;
			if (as) {
				linkEl.as = as;
			}
			document.head.append(linkEl);
		},
	},
};
</script>

<style lang="scss">
.video-youtube-iframe {
	background-color: $color-black;
	cursor: pointer;
	overflow: hidden;
	padding-top: 56.25%;
	position: relative;

	iframe {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
}
</style>
