<template>
	<div @keydown.esc="closeModal">
		<slot name="controller" :showModal="showModal"></slot>

		<transition name="modal-fade" @enter="transitionEnter">
			<div
				v-show="isModalVisible"
				class="modal-mask"
				:aria-hidden="isModalVisible ? 'false' : 'true'"
				@click="closeModal"
			>
				<div
					ref="modalContainer"
					:class="`modal ${modifiers}`"
					role="dialog"
					aria-labelledby="modalTitle"
					aria-describedby="modalDescription"
					tabindex="-1"
					@click.stop
					@keydown.shift.tab="handlePreviousTabNavigation"
					@keydown.tab.exact="handleNextTabNavigation"
				>
					<close-button
						:type="btnCloseModal"
						:event="closeModal"
						svg-icon=""
					></close-button>
					<slot name="modal-container"></slot>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import CloseButton from '../CloseButton';
import { EventBus } from '../../utils/EventBus';

export default {
	name: 'DefaultModal',

	components: {
		CloseButton,
	},

	props: {
		modalId: { type: String, required: false, default: null },
		modifiers: { type: String, required: false, default: null },
		video: { type: Boolean, required: false, default: false },
		btnCloseModal: { type: String, required: false, default: '-black' },
	},

	data() {
		return {
			isModalVisible: false,
			initialFocus: null,
			icon: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path data-name="Path 1586" d="M17.522 20L10 12.478 2.477 20 0 17.522 7.522 10 0 2.478 2.477 0l7.522 7.522L17.521 0l2.478 2.478L12.477 10l7.522 7.522z" fill="#fff" fill-rule="evenodd"/></svg>',
			backups: {
				body: {
					height: null,
					overflow: null,
				},
			},
		};
	},

	computed: {
		focusableElementList() {
			return this.$refs.modalContainer.querySelectorAll(
				'button, a[href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])'
			);
		},

		lastFocusableElementIndex() {
			return this.focusableElementList.length - 1;
		},
	},

	created() {
		EventBus.$on('open-modal', this.handleOpenModal);
	},

	methods: {
		handleOpenModal(eventModalId) {
			if (this.modalId === eventModalId) {
				this.showModal();
			}
		},

		showModal() {
			this.isModalVisible = true;
			this.initialFocus = document.activeElement;
			this.fixBody();
			this.setModalFocus();
			// this is not optimal solution and should be refactor
			if (this.video) {
				this.playVideo();
			}
		},

		transitionEnter() {
			EventBus.$emit('open-modal-transition');
		},

		closeModal() {
			this.isModalVisible = false;
			this.freeBody();
			this.restoreModalFocus();
			EventBus.$emit('close-modal', this.modalId);
			// this is not optimal solution and should be refactor
			if (this.video) {
				this.stopVideo();
			}
		},

		fixBody() {
			this.backups.body.height = document.body.style.height;
			this.backups.body.overflow = document.body.style.overflow;
			document.body.style.height = '100%';
			document.body.style.overflow = 'hidden';
		},

		freeBody() {
			document.body.style.height = this.backups.body.height;
			document.body.style.overflow = this.backups.body.overflow;
		},

		setModalFocus() {
			// had to use next tick, otherwise some other code would grab the focus
			this.$nextTick(function () {
				this.$refs.modalContainer.focus();
			});
		},

		restoreModalFocus() {
			this.initialFocus.focus();
		},

		handlePreviousTabNavigation(e) {
			// If there is only one element is in the list, prevent default and break
			if (this.focusableElementList.length <= 1) {
				e.preventDefault();
				return;
			}

			// if the current focused element is the first one, focus the last element
			if (document.activeElement === this.focusableElementList[0]) {
				e.preventDefault();
				// prettier-ignore
				this.focusableElementList[this.lastFocusableElementIndex].focus();
			}
		},

		handleNextTabNavigation(e) {
			// If there is only one element is in the list, prevent default and break
			if (this.focusableElementList.length <= 1) {
				e.preventDefault();
				return;
			}

			// if the current focused element is the last one, focus the first element
			// prettier-ignore
			if (document.activeElement === this.focusableElementList[this.lastFocusableElementIndex]) {
				e.preventDefault();
				this.focusableElementList[0].focus();
			}
		},

		playVideo() {
			const iframe = this.$el.querySelector('iframe');
			iframe.contentWindow.postMessage(
				JSON.stringify({ event: 'command', func: 'playVideo' }),
				'https://www.youtube.com'
			);
		},

		stopVideo() {
			const iframe = this.$el.querySelector('iframe');
			iframe.contentWindow.postMessage(
				JSON.stringify({ event: 'command', func: 'stopVideo' }),
				'https://www.youtube.com'
			);
		},
	},
};
</script>

<style lang="scss">
.close-btn {
	cursor: pointer;
}
</style>
