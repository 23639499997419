<template>
	<div :class="['accordion', { '-open': isOpen }]">
		<button
			class="accordion__button"
			:aria-expanded="isOpen"
			:data-gtm="gtm"
			:data-ga4="ga4"
			@click="openAccordion()"
		>
			<span class="accordion__title">
				<slot name="title"></slot>
			</span>
			<span
				v-if="hasContentSlot"
				class="accordion__icon"
				:class="isOpen ? 'accordion__icon--open' : ''"
			>
				<svg-icon v-if="icon" :icon="iconType" />
			</span>
		</button>

		<transition-expand v-if="hasContentSlot">
			<div v-show="isOpen" class="accordion__content">
				<slot name="content"></slot>
			</div>
		</transition-expand>
	</div>
</template>

<script>
import TransitionExpand from '../TransitionExpand';
import SvgIcon from '../SvgIcon';

export default {
	components: {
		TransitionExpand,
		SvgIcon,
	},

	props: {
		gtm: { type: String, required: false, default: undefined },
		ga4: { type: String, required: false, default: undefined },
		opened: { type: Boolean, required: false, default: false },
		openedDesktop: { type: Boolean, required: false, default: false },
		icon: { type: Boolean, required: false, default: true },
		iconType: { type: String, required: false, default: 'chevron' },
	},

	data() {
		return {
			isOpen: this.opened,
		};
	},
	computed: {
		media() {
			return this.$store.state.WindowWidth.name;
		},
		hasContentSlot() {
			return !!this.$slots.content;
		},
	},
	beforeMount() {
		if (this.media == 'desktop') {
			this.isOpen = this.openedDesktop;
		}
	},
	methods: {
		openAccordion: function () {
			this.isOpen = !this.isOpen;
		},
	},
};
</script>

<style></style>
