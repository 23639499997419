<template>
	<div class="image-mosaic">
		<div class="image-mosaic__header">
			<slot name="title" />
			<div v-if="$slots.description" class="image-mosaic__description">
				<slot name="description" />
			</div>
		</div>
		<slot name="ornement" />

		<div class="image-mosaic__list">
			<image-mosaic-item
				v-for="(mosaicItem, index) in mosaicData"
				:key="index"
				:index="index"
				:title="mosaicItem.title"
				:link="mosaicItem.link"
				:icon="mosaicItem.icon"
				:thumbnail="mosaicItem.thumbnail"
				:alt="mosaicItem.altText"
				:responsive-background-image-list="{
					default: mosaicItem.thumbnail,
				}"
				@open-modal="openModal"
			>
				<div>FallBack HighlightGalleryItem</div>
			</image-mosaic-item>
		</div>
	</div>
</template>

<script>
import { EventBus } from '../../utils/EventBus';
import ImageMosaicItem from '../ImageMosaicItem';

export default {
	components: {
		ImageMosaicItem,
	},

	props: {
		modalId: { type: String, default: null },
		mosaicData: { type: Array, required: true },
	},

	methods: {
		openModal: function (index) {
			EventBus.$emit('open-modal', this.modalId, { index });
		},
	},
};
</script>

<style lang="scss">
.image-mosaic {
	margin: 0 auto;
}

.image-mosaic__title {
	font-weight: bold;
	line-height: 1.1;
	text-align: center;
}

.image-mosaic__list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	@include tablet {
		overflow: hidden;
	}
}
</style>
