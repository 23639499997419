<template>
	<div class="hero__container" :style="style">
		<slot name="logo" />
		<h1 v-if="title" class="hero__title" v-html="title"></h1>
		<div v-if="desc" class="hero__desc" v-html="desc"></div>
		<slot />
	</div>
</template>

<script>
import ResponsiveBackgroundImageMixin from '../../mixins/ResponsiveBackgroundImageMixin.js';

export default {
	name: 'DefaultHero',
	mixins: [ResponsiveBackgroundImageMixin],
	props: {
		title: { type: String, required: true, default: '' },
		desc: { type: String, required: false, default: null },
		bgColor: { type: String, required: false, default: '' },
		fontColor: { type: String, required: false, default: 'white' },
	},
	data: function () {
		return {
			bgGradient: '',
			bgFadeGradient: '',
		};
	},
	computed: {
		style() {
			return {
				color: this.fontColor,
				'--bg-color': this.bgColor,
				backgroundImage: this.responsiveBackgroundImageUrl
					? `url(${this.responsiveBackgroundImageUrl}) ${this.bgFadeGradient}`
					: this.bgGradient,
			};
		},
		hasFadeColor() {
			return this.bgFadeColor !== '';
		},
	},
	mounted() {
		const reg = new RegExp('linear-gradient');
		if (reg.test(this.bgColor)) {
			this.bgGradient = this.bgColor;
		} else {
			this.bgGradient = `linear-gradient(${this.bgColor},${this.bgColor})`;
		}
		if (this.bgFadeColor !== '') {
			this.bgFadeGradient = `, linear-gradient(${this.bgFadeColor},${this.bgFadeColor})`;
		}
	},
	mounted() {
		const reg = new RegExp('linear-gradient');
		if (reg.test(this.bgColor)) {
			this.bgGradient = this.bgColor;
		} else {
			this.bgGradient = `linear-gradient(${this.bgColor},${this.bgColor})`;
		}
	},
};
</script>

<style lang="postcss" scoped></style>
