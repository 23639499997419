<template>
	<component
		:is="link ? 'a' : 'button'"
		:href="link"
		class="image-mosaic-item"
		@click="onButtonClick"
	>
		<span v-if="title" class="image-mosaic-item__title">
			<span class="image-mosaic-item__title__label">
				{{ title }}
				<span class="image-mosaic-item__title__icon">
					<svg-icon :icon="iconChecked"></svg-icon>
				</span>
			</span>
		</span>
		<div class="image-mosaic-item__thumbnail">
			<img :alt="alt" :src="thumbnail" loading="lazy" />
		</div>
	</component>
</template>

<script>
import SvgIcon from '../SvgIcon';

export default {
	components: {
		SvgIcon,
	},

	props: {
		index: { type: Number, required: true },
		title: { type: String, required: true },
		link: { type: String, default: null },
		thumbnail: { type: String, default: null },
		alt: { type: String, required: false, default: null },
		icon: { type: String, required: false, default: 'magnifier-circled' },
	},

	data() {
		return {
			iconChecked: this.icon ? this.icon : 'magnifier-circled',
		};
	},

	methods: {
		onButtonClick: function (e) {
			if (!this.link) {
				e.preventDefault();
				this.$emit('open-modal', this.index);
			}
		},
	},
};
</script>

<style lang="scss">
.image-mosaic-item {
	background-color: transparent;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 102%;
	border: 0;
	color: inherit;
	cursor: pointer;
	display: flex;
	flex-flow: nowrap column;
	font-weight: bold;
	height: 50vw;
	justify-content: flex-end;
	margin: 0;
	padding: 0;
	width: 50%;
	position: relative;
	overflow: hidden;

	@include tablet {
		height: calc(24vw - 10px);
		max-height: 357px;
		width: 25%;
	}

	@include desktop {
		height: 24vw;
	}

	&:hover,
	&:focus {
		.image-mosaic-item__thumbnail {
			transition: transform 0.8s cubic-bezier(0.35, 0, 0, 1);
			transform: scale(1.05);
		}
	}
}

.image-mosaic-item__thumbnail {
	width: 100%;
	height: 100%;
	position: absolute;
	transition: transform 0.6s cubic-bezier(0.35, 0, 0, 1);

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: inline-block;
		background: linear-gradient(rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 1));
	}
}

.image-mosaic-item__title {
	font-size: inherit;
	z-index: 2;
	display: inline-flex;
}

.image-mosaic-item__title__label {
	margin-right: 34px;
	text-shadow: 2px 2px 4px $color-black;
}

.image-mosaic-item__title__icon {
	display: inline-flex;
	height: 1em;
	margin-left: 4px;
	margin-right: 8px;
	position: relative;
	width: 0;

	> svg {
		bottom: 50%;
		display: block;
		fill: currentColor;
		position: absolute;
		stroke: none;
		top: 50%;
		transform: translateY(calc(-50% + 3px));
		filter: drop-shadow(1px 2px 1px $color-black);
	}
}
</style>
