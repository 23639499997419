import Vue from 'vue';
import Vuex from 'vuex';

import WindowWidth from './modules/WindowWidth.js';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		WindowWidth,
	},

	state: {
		hasAnimation: true,
	},

	mutations: {
		toggleAnimation(state, forceState) {
			state.hasAnimation =
				typeof force == 'boolean' ? forceState : !state.hasAnimation;
		},
	},

	actions: {},
});
