export default {
	state: {
		name: '',
	},

	mutations: {
		SET_NAME(state, name) {
			state.name = name;
		},
	},

	actions: {},
	getters: {},
};
