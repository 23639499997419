<template>
	<component :is="svg"></component>
</template>

<script>
import SvgArena from '@/assets/svg/arena.svg';
import SvgArmchair from '@/assets/svg/armchair.svg';
import SvgArrowCarousel from '@/assets/svg/arrow-carousel.svg';
import SvgArrowDownOutline from '@/assets/svg/arrow-down-outline.svg';
import SvgArrowRight from '@/assets/svg/arrow-right.svg';
import SvgArrowRightCircled from '@/assets/svg/arrow-right-circled.svg';
import SvgArrowRightOutline from '@/assets/svg/arrow-right-outline.svg';
import SvgBigTop from '@/assets/svg/big-top.svg';
import SvgCalendar from '@/assets/svg/calendar.svg';
import SvgCheckVerified from '@/assets/svg/check-verified.svg';
import SvgChevron from '@/assets/svg/chevron.svg';
import SvgChevronCreactive from '@/assets/svg/chevron-creactive.svg';
import SvgClock from '@/assets/svg/clock.svg';
import SvgDollarTag from '@/assets/svg/dollar-tag.svg';
import SvgEnvelope from '@/assets/svg/envelope.svg';
import SvgFacebook from '@/assets/svg/facebook.svg';
import SvgGift from '@/assets/svg/gift.svg';
import SvgGroup from '@/assets/svg/group.svg';
import SvgInfo from '@/assets/svg/info-inverted.svg';
import SvgInfoInverted from '@/assets/svg/info.svg';
import SvgInstagram from '@/assets/svg/instagram.svg';
import SvgLocationPin from '@/assets/svg/location-pin.svg';
import SvgLock from '@/assets/svg/lock.svg';
import SvgMagnifierCircled from '@/assets/svg/magnifier-circled.svg';
import SvgPause from '@/assets/svg/pause.svg';
import SvgPauseCircledFull from '@/assets/svg/pause-circled-full.svg';
import SvgPercentTag from '@/assets/svg/percent-tag.svg';
import SvgPhone from '@/assets/svg/phone.svg';
import SvgPlay from '@/assets/svg/play.svg';
import SvgPlayCircled from '@/assets/svg/play-circled.svg';
import SvgPlayCircledFull from '@/assets/svg/play-circled-full.svg';
import SvgTicket from '@/assets/svg/ticket.svg';
import SvgTicketInverted from '@/assets/svg/ticket-inverted.svg';
import SvgTshirt from '@/assets/svg/tshirt.svg';
import SvgArrowCalendar from '@/assets/svg/arrow-calendar.svg';
import SvgTwitter from '@/assets/svg/twitter.svg';
import SvgYoutube from '@/assets/svg/youtube.svg';
import SvgAnimationOn from '@/assets/svg/animations_on.svg';
import SvgAnimationOff from '@/assets/svg/animations_off.svg';
import SvgMapIcon from '@/assets/svg/map-icon.svg';
import SvgCityIcon from '@/assets/svg/City-icon.svg';
import SvgShowIcon from '@/assets/svg/Show-icon.svg';
import SvgVenueIcon from '@/assets/svg/Venue-icon.svg';
import SvgCirqueLogoGold from '@/assets/svg/cirque-logo-gold.svg';
import SvgLocateIcon from '@/assets/svg/locate-icon.svg';
import SvgClearFieldIcon from '@/assets/svg/clear-field-icon.svg';
import SvgArrowLeftCircleIcon from '@/assets/svg/arrow-left-circle-icon.svg';
import SvgSoundOnIcon from '@/assets/svg/sound-on-icon.svg';
import SvgSoundOffIcon from '@/assets/svg/sound-off-icon.svg';
import SvgCollaborationIcon from '@/assets/svg/collaboration-icon.svg';
import SvgHealthBoxIcon from '@/assets/svg/health-box-icon.svg';
import SvgOfficeDeskIcon from '@/assets/svg/office-desk-icon.svg';
import SvgMoneyBagIcon from '@/assets/svg/money-bag-icon.svg';
import SvgTrioTeamIcon from '@/assets/svg/trio-team-icon.svg';
import SvgCheckedFileIcon from '@/assets/svg/checked-file-icon.svg';
import SvgVacationCalendarIcon from '@/assets/svg/vacation-calendar-icon.svg';

export default {
	components: {
		SvgArena,
		SvgArmchair,
		SvgArrowCarousel,
		SvgArrowDownOutline,
		SvgArrowRight,
		SvgArrowRightCircled,
		SvgArrowRightOutline,
		SvgBigTop,
		SvgCalendar,
		SvgCheckVerified,
		SvgChevron,
		SvgChevronCreactive,
		SvgClock,
		SvgDollarTag,
		SvgEnvelope,
		SvgFacebook,
		SvgGift,
		SvgGroup,
		SvgInfo,
		SvgInfoInverted,
		SvgInstagram,
		SvgLocationPin,
		SvgLock,
		SvgMagnifierCircled,
		SvgPause,
		SvgPauseCircledFull,
		SvgPercentTag,
		SvgPhone,
		SvgPlay,
		SvgPlayCircled,
		SvgPlayCircledFull,
		SvgTicket,
		SvgTicketInverted,
		SvgTshirt,
		SvgTwitter,
		SvgYoutube,
		SvgAnimationOn,
		SvgAnimationOff,
		SvgMapIcon,
		SvgArrowCalendar,
		SvgCityIcon,
		SvgShowIcon,
		SvgVenueIcon,
		SvgCirqueLogoGold,
		SvgLocateIcon,
		SvgClearFieldIcon,
		SvgArrowLeftCircleIcon,
		SvgSoundOnIcon,
		SvgSoundOffIcon,
		SvgCollaborationIcon,
		SvgHealthBoxIcon,
		SvgOfficeDeskIcon,
		SvgMoneyBagIcon,
		SvgTrioTeamIcon,
		SvgCheckedFileIcon,
		SvgVacationCalendarIcon,
	},

	props: {
		icon: { type: String, required: true },
	},

	computed: {
		svg() {
			return {
				arena: 'SvgArena',
				armchair: 'SvgArmchair',
				'arrow-down-outline': 'SvgArrowDownOutline',
				'arrow-right-circled': 'SvgArrowRightCircled',
				'arrow-right-outline': 'SvgArrowRightOutline',
				'big-top': 'SvgBigTop',
				calendar: 'SvgCalendar',
				chevron: 'SvgChevron',
				'chevron-creactive': 'SvgChevronCreactive',
				clock: 'SvgClock',
				'check-verified': 'SvgCheckVerified',
				'dollar-tag': 'SvgDollarTag',
				facebook: 'SvgFacebook',
				envelope: 'SvgEnvelope',
				gift: 'SvgGift',
				group: 'SvgGroup',
				info: 'SvgInfoInverted',
				'info-inverted': 'SvgInfo',
				instagram: 'SvgInstagram',
				'location-pin': 'SvgLocationPin',
				lock: 'SvgLock',
				'magnifier-circled': 'SvgMagnifierCircled',
				pause: 'SvgPause',
				'pause-circled-full': 'SvgPauseCircledFull',
				'percent-tag': 'SvgPercentTag',
				phone: 'SvgPhone',
				play: 'SvgPlay',
				'play-circled': 'SvgPlayCircled',
				'play-circled-full': 'SvgPlayCircledFull',
				ticket: 'SvgTicket',
				'ticket-inverted': 'SvgTicketInverted',
				tshirt: 'SvgTshirt',
				'arrow-right': 'SvgArrowRight',
				'arrow-carousel': 'SvgArrowCarousel',
				'arrow-calendar': 'SvgArrowCalendar',
				twitter: 'SvgTwitter',
				youtube: 'SvgYoutube',
				'animation-on': 'SvgAnimationOn',
				'animation-off': 'SvgAnimationOff',
				'map-icon': 'SvgMapIcon',
				'City-icon': 'SvgCityIcon',
				'Show-icon': 'SvgShowIcon',
				'Venue-icon': 'SvgVenueIcon',
				'cirque-logo-gold': 'SvgCirqueLogoGold',
				'locate-icon': 'SvgLocateIcon',
				'clear-field-icon': 'SvgClearFieldIcon',
				'arrow-left-circle-icon': 'SvgArrowLeftCircleIcon',
				'sound-on-icon': 'SvgSoundOnIcon',
				'sound-off-icon': 'SvgSoundOffIcon',
				'collaboration-icon': 'SvgCollaborationIcon',
				'health-box-icon': 'SvgHealthBoxIcon',
				'office-desk-icon': 'SvgOfficeDeskIcon',
				'money-bag-icon': 'SvgMoneyBagIcon',
				'trio-team-icon': 'SvgTrioTeamIcon',
				'checked-file-icon': 'SvgCheckedFileIcon',
				'vacation-calendar-icon': 'SvgVacationCalendarIcon',
			}[this.icon];
		},
	},
};
</script>
