<template>
	<div class="video-carousel">
		<div class="video-carousel__header-info">
			<slot name="title" />

			<div v-if="$slots.description" class="video-carousel__description">
				<slot name="description" />
				<slot name="link" />
			</div>
		</div>

		<div class="video-carousel__wrapper" :class="singleVideo">
			<video-youtube-player
				ref="player"
				:video-id="currentVideoId"
				poster-size="sddefault"
			></video-youtube-player>

			<div class="video-carousel__bottom">
				<video-carousel-thumb-list
					v-if="videoData.length > 1"
					:thumb-data="videoData"
					:selected-index="currentVideoIndex"
					:label-prev-button="labelPrevButton"
					:label-next-button="labelNextButton"
					:label-play-video-button="labelPlayVideoButton"
					@thumb-click="onThumnailClick"
				/>

				<div
					v-if="subTitle || subDescription"
					class="video-carousel__sub-content"
					aria-live="polite"
				>
					<component
						:is="subContentHeadingLevel"
						v-if="subTitle"
						class="video-carousel__sub-content__title"
					>
						{{ subTitle }}
					</component>

					<div
						v-if="subDescription"
						class="video-carousel__sub-content__description"
						v-html="subDescription"
					></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SvgIcon from '../SvgIcon';
import VideoYoutubePlayer from '../VideoYoutubePlayer';
import VideoCarouselThumbList from '../VideoCarouselThumbList';

export default {
	name: 'VideoCarousel',

	components: {
		SvgIcon,
		VideoYoutubePlayer,
		VideoCarouselThumbList,
	},

	props: {
		subContentHeadingLevel: { type: String, default: 'h3' },
		labelPrevButton: { type: String, required: true },
		labelNextButton: { type: String, required: true },
		labelPlayVideoButton: { type: String, required: true },
		videoData: { type: Array, required: true },
	},

	data() {
		return {
			currentVideoIndex: 0,
		};
	},

	computed: {
		currentVideoId() {
			return this.videoData[this.currentVideoIndex].videoId;
		},
		subTitle() {
			return this.videoData[this.currentVideoIndex].title;
		},
		subDescription() {
			return this.videoData[this.currentVideoIndex].description;
		},
		singleVideo() {
			return {
				'video-carousel__wrapper--single': this.videoData.length === 1,
			};
		},
	},

	methods: {
		onThumnailClick(index) {
			this.currentVideoIndex = index;
			if (this.$refs.player) {
				this.$refs.player.clickThumbnail();
			}
		},
	},
};
</script>

<style lang="scss"></style>
