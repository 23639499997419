import { render, staticRenderFns } from "./VideoYoutubePlayer.vue?vue&type=template&id=4406d152&"
import script from "./VideoYoutubePlayer.vue?vue&type=script&lang=js&"
export * from "./VideoYoutubePlayer.vue?vue&type=script&lang=js&"
import style0 from "./VideoYoutubePlayer.vue?vue&type=style&index=0&id=4406d152&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports